import React from "react"
import { FaCode, FaSketch, FaAndroid } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `I'm well-versed with both nodejs and java spring boot frameworks to build robust web applications with full-proof backends. I have made multiple web applications using reactjs and nextjs.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "artificial intelligence",
    text: `I have made multiple projects and published a paper in Natural Language Processing and Artifical Intelligence`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "programming",
    text: `I know advance java and python programming languages. I have also worked with various popular frameworks in these languages for AI and web development.`,
  },
]
